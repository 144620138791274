<template>
  <div class="page-height" style="padding-bottom: 20px;">
    <div class="flex-center dataDirectory-top">
      <div class="w1200">
        <div class="page-title">数据目录</div>
        <div class="box-info">
          生猪大数据数智云脑平台采集的数据，形成全貌的生猪产业数据目录。企业可以更好地管理和利用数据资源，推动企业的数字化转型和智能化发展。
        </div>
      </div>
    </div>
    <div class="flex-center dataDirectory-menu">
      <div class="w1200 d-flex">
        <div v-for="item in menuList" :class="menuItem.id == item.id ? 'on' : ''" class="menu-item"
             @click="menuChange(item)">{{ item.type_name }}
        </div>
      </div>
    </div>
    <div class="flex-center">
      <div class="w1200">
        <div class="data-input-line box">
          <div class="data-input-type" @click="showType = !showType">
            <div class="limit1">{{ subMenuItem.type_name ? subMenuItem.type_name : '所有分类' }}</div>
            <i class="icon-open iconfont"></i></div>
          <el-input
              v-model="keyWords"
              clearable
              placeholder="请输入关键词，支持模糊搜索。"
              @change="initData"
              @clear="initData">
          </el-input>
          <div class="iconfont icon-icon-search_7eb btn-shadow-color"></div>
          <div v-if="showType" class="data-type-list box">
            <div class="data-type-title">所有分类</div>
            <div class="data-type-list-cont">
              <div v-for="item in subMenuList" :class="subMenuItem.id == item.id ? 'on' : ''" class="data-type-item"
                   @click="typeChange(item)">
                <div class="data-type-item-label">{{ item.type_name }}</div>
                <div class="iconfont icon-gou"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-between">
          <div v-loading="subMenuLoading" class="data-cont-left box">
            <div :class="!subMenuItem.id ? 'on' : ''" class="limit1" @click="typeChange({})">所有分类</div>
            <template v-for="item in subMenuList">
              <el-tooltip content="价格数据" effect="light" placement="right">
                <div :class="subMenuItem.id == item.id ? 'on' : ''" class="limit1" @click="typeChange(item)">
                  {{ item.type_name }}
                </div>
              </el-tooltip>
            </template>
          </div>
          <div v-loading="dataLoading" class="data-cont-right box">
            <div v-for="item in list" class="data-line flex-between" @click="goPage(item.resource_id,menuItem.type_name)">
              <el-tooltip :content="item.resource_name" effect="light" placement="right">
                <div class="data-line-label limit1" v-html="item.resource_name"></div>
              </el-tooltip>
              <div class="data-line-cont limit1">
                <template v-if="item.columns_name">
                  <span v-for="subItem in item.columns_name.split(',')" v-html="subItem"></span>
                </template>
              </div>
              <router-link class="iconfont icon-bg-right btn-shadow-color" :to="{path:'/dataDirectory/detail',query:{id:item.resource_id,type:menuItem.type_name}}"></router-link>

            </div>
            <div v-if="list.length" class="page-number">
              <el-pagination
                  :current-page.sync="page"
                  :page-size="10"
                  :total="total"
                  layout="prev, pager, next, jumper"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange">
              </el-pagination>
              <div class="page-number-box page-number-btn" @click="initData">前往</div>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {IndexTypeQuery, ResourceListQuery} from "@/assets/js/api";

export default {
  name: "DataDirectory",
  data() {
    return {
      showType: false,
      menuList: [],
      menuItem: null,
      subMenuItem: {},
      subMenuList: [],
      subMenuLoading: false,
      keyWords: '',
      page: 1,
      total: 0,
      list: [],
      dataLoading: false
    }
  },
  mounted() {
    IndexTypeQuery({parent_id: 0}).then(res => {
      this.menuList = res.data
      this.menuItem = res.data[0]
      this.initMenu(this.menuItem.id)
    })
  },
  methods: {
    initMenu(id) {
      this.subMenuList = []
      this.subMenuLoading = true
      IndexTypeQuery({parent_id: id}).then(res => {
        this.subMenuList = res.data
        this.initData()
        this.subMenuLoading = false
      }).catch(() => {
        this.subMenuLoading = false
      })
    },
    menuChange(item) {
      if(this.dataLoading){
        this.$message({message: '数据加载中，请稍后', type: 'info'});
        return false
      }
      this.page = 1
      this.menuItem = item
      this.subMenuItem = {}
      this.showType = false
      this.initMenu(this.menuItem.id)
    },
    typeChange(item) {
      if(this.dataLoading){
        this.$message({message: '数据加载中，请稍后', type: 'info'});
        return false
      }
      this.page = 1
      this.showType = false
      if (this.subMenuItem == item) {
        this.subMenuItem = {}
      } else {
        this.subMenuItem = item
      }
      this.initData()
    },
    initData() {
      this.list = []
      this.dataLoading = true
      let query = {
        offsetPage: this.page,
        offsetSize: 10,
        indexTypeId: this.subMenuItem.id ? this.subMenuItem.id : null
      }
      if (this.menuItem.type_name == '数据服务') {
        query = {
          resourceType: '2',
          serviceName: this.keyWords ? this.keyWords : null,
          ...query
        }
      } else {
        query = {
          resourceType: '3',
          aseetName: this.keyWords ? this.keyWords : null,
          ...query
        }
      }
      ResourceListQuery(query).then(res => {
        if (this.keyWords.trim()) {
          this.list = res.data.map(item => {
            return {
              ...item,
              resource_name: item.resource_name.replaceAll(this.keyWords, '<span class="blue">' + this.keyWords + '</span>')};
          });
        } else {
          this.list = res.data
        }

        this.total = res.total
        this.dataLoading = false
      }).catch(() => {
        this.dataLoading = false
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.initData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initData()
    },
    goPage(id,type){
      this.$router.push({
        path:'/dataDirectory/detail?id=' + id + '&type=' + type
      })
    }
  },
}
</script>

<style scoped>
@import "../assets/css/dataDirectory/dataDirectory.css";
</style>
